<template>
    <div class="d-flex justify-content-center align-items-center h-100">
        <div class="satellite-wrapper">
            <img src="@/assets/img/satellite_big.png" alt="Satellite" class="satellite-img img-fluid" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'LeftSatellite'
}
</script>

<style lang="scss">
.satellite {
    &-img {
        margin-top: -60px;
    }
}
</style>