<template>
    <div class="spinner-block w-100 h-100 justify-content-center align-items-center overflow-hidden">
        <div class="spinner spinner-loader d-flex justify-content-center align-items-center">
            <div class="spinner-text-wrapper d-flex justify-content-center align-items-center">
                <h2 class="spinner-text-inner m-0 p-0">{{ percentage }}</h2>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Loader',
    props: ['percentage']
}
</script>

<style lang="scss">
$border-width: 4px;
$basic-black: rgba(0, 0, 0, 0);
$basic-spinner-dimensions: 320px;
$basic-dark-color: rgba(#fff, .2);
$basic-text-wrapper-background: rgba(10, 0, 95, 0.5);
$main-spinner-dimensions: $basic-spinner-dimensions - $border-width * 2;
$small-spinner-dimensions: $main-spinner-dimensions * .7;

.spinner {
    &-block {
        display: flex;
        position: absolute;
        z-index: 2;
        background: #000 url("../assets/img/background_preloader.jpeg") no-repeat center center fixed;
        background-size: cover;
    }
    &-text {
        &-wrapper {
            width: 200px;
            height: 200px;
            background: radial-gradient(50% 50% at 50% 50%, $basic-text-wrapper-background 0%, $basic-black 100%);
        }
        &-inner {
            font-family: Inter, sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 29px;
        }
    }
    &-loader {
        position: relative;
        width: $basic-spinner-dimensions;
        height: $basic-spinner-dimensions;

        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            border-width: 4px;
            border-style: solid;
            border-radius: 50%;
        }
        @keyframes scaleCircle {
            0% {
                transform: scale(0);
                opacity: 0;
            }

            50% {
                transform: scale(0.7);
                opacity: 1;
            }

            100% {
                transform: scale(1);
                opacity: 0;
            }
        }

        &:before {
            width: $main-spinner-dimensions;
            height: $main-spinner-dimensions;
            border-color: $basic-dark-color;
            top: 0;
            left: 0;
            animation: scaleCircle 2s linear 0s infinite;
        }
        &:after {
            width: $main-spinner-dimensions;
            height: $main-spinner-dimensions;
            border-color: $basic-dark-color;
            top: 0;
            left: 0;
            opacity: 0;
            animation: scaleCircle 2s linear 0.5s infinite;
        }
    }
}
</style>