<template>
    <video id="video" poster="@/assets/img/background.jpeg" autoplay muted loop>
        <source src="@/assets/video/background_video.mp4" type="video/mp4">
    </video>
</template>

<script>
export default {
    name: 'BackgroundVideo'
}
</script>

<style lang="scss">
#video {
    position: fixed;
    top: 0;
    z-index: -1;
}
@media (min-aspect-ratio: 16/9) {
    #video {
        width: 100%;
        height: auto;
    }
}
@media (max-aspect-ratio: 16/9) {
    #video {
        width: auto;
        height: 100%;
    }
}
</style>