<template>
    <div class="locales-wrapper">
        <b-list-group horizontal class="locales-menu m-0 p-0">
            <b-list-group-item>
                <span :class="`${currPath === '/' ? 'text-white': 'text-white-50'}`" @click="changeLocale('/')">O'Z</span>
            </b-list-group-item>
            <b-list-group-item>
                <span :class="`${currPath === '/ru' ? 'text-white': 'text-white-50'}`" @click="changeLocale('ru')">РУ</span>
            </b-list-group-item>
            <b-list-group-item>
                <span :class="`${currPath === '/en' ? 'text-white': 'text-white-50'}`" @click="changeLocale('en')">EN</span>
            </b-list-group-item>
        </b-list-group>
    </div>
</template>

<script>
export default {
    name: 'Locales',
    data: () => ({
        currPath: '/'
    }),
    mounted() {
        setTimeout(() => {
            this.currPath = this.$route.path;
        }, 1000);
    },
    methods: {
        changeLocale(locale) {
            document.location.href = locale;
        }
    }
}
</script>

<style lang="scss">
.locales {
    &-wrapper {
        position: absolute;
        z-index: 1;
        top: 40px;
        left: 40px;
    }
    &-menu {
        list-style: none;
    }

    @media screen and (max-width: 991px) {
        &-wrapper {
            top: 20px;
            left: auto;
            right: 40px;
        }
    }
}
.list-group-item {
    background-color: transparent !important;
    border: none !important;

    &:hover {
        cursor: pointer;
    }
}
</style>