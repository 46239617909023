import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    { path: '/', name: 'Uz', component: () => import('@/pages/Uz') },
    { path: '/ru', name: 'Ru', component: () => import('@/pages/Ru') },
    { path: '/en', name: 'En', component: () => import('@/pages/En') },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
