<template>
    <div class="d-flex justify-content-lg-end w-100">
        <img src="@/assets/img/logo.png" alt="UzSAT" class="logo" />
    </div>
</template>

<script>
export default {
    name: 'Logo'
}
</script>

<style lang="scss">
.logo {
    width: 60px;
    height: 55px;
    margin: 20px 0 40px 0;

    @media screen and (min-width: 1200px) {
        width: auto;
        height: auto;
    }

    @media screen and (min-width: 1920px) {
        margin: 80px 80px 0 0;
    }
}
</style>