<template>
    <div id="app">
        <transition-group name="fade">
            <loader key="loader" :percentage="loaded" v-show="loadingPercent !== 100" />
            <b-container key="main" fluid class="h-100">
                <b-row class="h-100">
                    <locales />
                    <b-col lg="5" class="d-none d-lg-block">
                        <left-satellite />
                    </b-col>
                    <b-col cols="12" lg="5" offset-lg="2">
                        <b-container fluid class="h-100">
                            <b-row class="flex-column h-100">
                                <b-col>
                                    <b-row>
                                        <logo />
                                    </b-row>
                                </b-col>
                                <b-col>
                                    <router-view />
                                </b-col>
                                <b-col>
                                    <right-footer />
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-col>
                </b-row>
            </b-container>
            <background-video key="video" />
        </transition-group>
    </div>
</template>

<script>
import Logo from '@/components/Logo'
import Loader from '@/components/Loader'
import Locales from '@/components/Locales'
import RightFooter from '@/components/RightFooter'
import LeftSatellite from '@/components/LeftSatellite'
import BackgroundVideo from '@/components/BackgroundVideo'

export default {
    name: 'App',
    components: { Locales, Loader, LeftSatellite, Logo, RightFooter, BackgroundVideo },
    data: () => ({
        loadingPercent: 0,
        loadTime: 0,
        interval: null

    }),
    created() {
        let perfData = window.performance.timing;
        let estimatedTime = Math.abs(perfData.loadEventEnd - perfData.navigationStart);

        this.loadTime = parseInt((estimatedTime / 1000) % 60) * 100;
        this.doProgress();
    },
    methods: {
        doProgress() {
            let step = this.loadTime / 100;

            this.interval = setInterval(() => {
                this.loadingPercent++
            }, step);
        }
    },
    computed: {
        loaded() {
            return this.loadingPercent + '%'
        }
    },
    watch: {
        loadingPercent(val) {
            if (val >= 100) {
                clearInterval(this.interval)
            }
        }
    },
}
</script>

<style lang="scss">
html,
body {
    width:100%;
    height: 100%;
}
body {
    background: url("./assets/img/background.jpeg") no-repeat center center fixed;
    background-size: cover !important;
}
#app {
    width: inherit;
    height: inherit;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity 2s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>
