<template>
    <b-container fluid class="p-0 h-100">
        <b-row class="h-100">
            <b-col cols="12" class="footer-col footer-col--first">
                <b-row class="h-100">
                    <div class="footer-list">
                        <img src="@/assets/img/phone.png" alt="phone" class="footer-img" />
                        <a href="tel:+998335000500" class="text-white footer-link">+998 33 500 0 500</a>
                    </div>
                </b-row>
            </b-col>
            <b-col cols="12" class="footer-col footer-col--center">
                <b-row class="h-100">
                    <div class="footer-list">
                        <img src="@/assets/img/mail.png" alt="mail" class="footer-img" />
                        <a href="mailto:info@uzsat.uz" class="text-white footer-link">info@uzsat.uz</a>
                    </div>
                </b-row>
            </b-col>
            <b-col cols="12" class="footer-col footer-col--last">
                <b-row class="h-100">
                    <div class="footer-list">
                            <span class="footer-link footer-link--copy text-white">
                                &copy; {{ new Date().getFullYear() }} UZSAT
                            </span>
                    </div>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style lang="scss">
.footer {
    &-img,
    &-link {
        align-self: flex-end;
    }
    &-img {
        margin-right: 10px;
    }

    @media screen and (max-width: 767px) {
        &-col {
            height: auto !important;

            &--first {
                margin-top: 20px;
            }
        }
        &-img {
            margin-right: 13px;
        }
        &-link {
            font-family: Inter, serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;

            &--copy {
                font-family: Inter, serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 15px;
            }
        }
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        &-col {
            flex: 0 0 36% !important;
            max-width: 36% !important;

            &--center {
                .footer-list {
                    width: 100%;
                    justify-content: center;
                }
            }
            &--last {
                flex: 0 0 28% !important;
                max-width: 28% !important;

                .footer-list {
                    width: 100%;
                    justify-content: flex-end;
                }
            }
        }
        &-list {
            display: flex;
            margin-bottom: 1rem;
        }
        &-img {
            position: relative;
            top: -2px;
        }
    }

    @media screen and (min-width: 992px) {
        &-col {
            height: auto !important;

            &--first {
                margin-top: 20px;
            }
        }
    }

    @media screen and (min-width: 1200px) {
        &-link {
            font-family: Inter, sans-serif;
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1439px) {
        &-col--first {
            margin-top: 5rem;
        }
    }

    @media screen and (min-width: 1440px) {
        &-col {
            flex: 0 0 36% !important;
            max-width: 36% !important;

            &--center {
                .footer-list {
                    width: 100%;
                    justify-content: center;
                }
            }
            &--last {
                flex: 0 0 28% !important;
                max-width: 28% !important;

                .footer-list {
                    width: 100%;
                    justify-content: flex-end;
                }
            }
        }
        &-list {
            display: flex;
            margin-bottom: 1rem;
        }
        &-img {
            position: relative;
            top: -2px;
        }
    }

    @media screen and (min-width: 1600px) {
        &-list {
            display: flex;
        }
        &-link {
            font-size: 18px;
        }
    }
}
</style>